<app-rp-loader></app-rp-loader>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="wrapper">
          <div class="content-container">
            <div class="card-body" style="height: 600px;">
              <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                
                <!-- Battery Status Report -->
                <accordion-group [isOpen]="isFirstOpen" heading="1. Device communication Report" class="accordion-item" #group>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24" [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control date"
                              [value]="DeviceCommunicationnDateRange ? formatDateRange(DeviceCommunicationnDateRange) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker 
                              bsDaterangepicker [bsConfig]="datePickerConfig"
                              [(bsValue)]="DeviceCommunicationnDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarDeviceCommunication()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="DeviceCommunicationnDateRange?.length > 0"
                              (click)="clearDeviceCommunicationDate()"></span>
                          </div>
                          <div *ngIf="!DeviceCommunicationnDateRange?.length&&validationforDeviceCommunication" class="text-danger mt-2">
                            {{validationforDeviceCommunication}}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="name" [(ngModel)]="DeviceCommunication_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInDeviceCommunication($event)"
                            [multiple]="true" [clearable]="false" [deselectOnClick]="false">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <!-- <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span> -->
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <!-- <div *ngIf="utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div> -->
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Project <span class="required">*</span></label>

                          <ng-select [items]="projectsList" placeholder="1.Select Projects" bindLabel="name"
                            bindValue="name" [(ngModel)]="selectedProject" (change)="onProjectFilterChange($event)"
                            (add)="onSelectProject($event)" [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="selectedProject.length==0&&ProjectValidationMessage" class="text-danger mt-2">
                            {{ ProjectValidationMessage }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3 align-items-start sm-btn mt-4">
                        <button class="btn btn-primary btn-md" (click)="exportReport('deviceCommunication')">Export</button>
                      </div>
                    </div>
                  </div>
                </accordion-group>

                <!-- Settlement Report -->
                <accordion-group [isOpen]="isopen" heading="2.Abnormal Readings Report" class="accordion-item" #group>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24" [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control settlement"
                              [value]="settlemetDateRange ? (formatDateRange(settlemetDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="settlemetDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarsettlement()"></span>
                            <!-- Clear icon -->
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="settlemetDateRange.length>0"
                              (click)="clearDateSettlement()"></span>

                          </div>
                          <div *ngIf="settlemetDateRange.length==0&&validationForAbnormalReading"
                            class="text-danger mt-2">
                            {{ validationForAbnormalReading }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="name" [(ngModel)]="abnormal_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInAbnormalReadings($event)"
                            [multiple]="true" [clearable]="false" [deselectOnClick]="false">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <!-- <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span> -->
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <!-- <div *ngIf="utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div> -->
                        </div>
                      </div>


                      <div class="col-lg-3 align-items-start sm-btn mt-4">
                        <button class="btn btn-primary btn-md" (click)="exportReport('AbnormalReading')">Export</button>
                      </div>
                    </div>
                  </div>
                </accordion-group>

              </accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div>
