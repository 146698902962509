import { Component, OnInit, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { DashboardService } from '../../core/services/dashboard.service';
import { error } from 'node:console';
import { superadmin_graphColors } from '../../shared/constants/enum';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  process,
  State,
  SortDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { table_pageSize } from '../../shared/modals/common';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../core/services/loader.service';

@Component({
  selector: 'app-rp-dashboard',
  templateUrl: './rp-dashboard.component.html',
  styleUrl: './rp-dashboard.component.scss',
})
export class RpDashboardComponent {
  colors_status = superadmin_graphColors;
  totalgatewayCount: number = 0;
  totalmeterdeviceCount: number = 0
  gatewayCounts: any[] = []
  constructor(private dashboardService: DashboardService, private sharedService: SharedService, private router: Router, private loaderService: LoaderService,) { }
  dashboardData: any;
  communicationStatusGraphData: any[] = [];
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  responsePageSize: number = 10;
  totalResponseConfigCount: any;
  abnormalReadingList: any = [];
  rowDates: string[] = [];
  columns: any[] = [];
  /*  abnormalReadingList: any = [
     {
       "utilityType": "Water",
       "datewiseCounts": {
         "30-01-2025": 2170,
         "29-01-2025": 3980,
         "28-01-2025": 245
       }
     },
     {
       "utilityType": "Gas",
       "datewiseCounts": {
         "30-01-2025": 6,
         "29-01-2025": 82,
         "28-01-2025": 3
       }
     },
     {
       "utilityType": "Electricity",
       "datewiseCounts": {
         "30-01-2025": 709,
         "29-01-2025": 1972,
         "28-01-2025": 144
       }
     }
   ]; */

  abnoramReadingGridData: any[] = []
  refreshDashboard() {
    this.loaderService.setLoading(true);
    this.getDahboardAPi();
  }
  ngOnInit(): void {
    this.loaderService.setLoading(true);
    this.getDahboardAPi();


  }
  getDahboardAPi() {
    this.dashboardService.getDashboardData().subscribe({
      next: (res) => {
        console.log(res);
        this.dashboardData = res.data;
        this.totalmeterdeviceCount = this.dashboardData?.idNonCommCount + this.dashboardData?.idCommCount
        // console.log(this.dashboardData)
        this.totalgatewayCount = this.dashboardData?.gwCommCount + this.dashboardData?.gwNonCommCount;
        console.log("husuHDOD---->", this.totalgatewayCount)
        this.gatewayCounts = [
          { name: 'Gateways Communicating', value: this.dashboardData?.gwCommCount, color: '#DAFFF2' },
          {
            name: 'Gateways Non Communicating',
            value: this.dashboardData?.gwNonCommCount,
            color: '#FFE5E5',
          },
          {
            name: ' Total Gateways',
            value: this.totalgatewayCount,
            color: '#FFEFCF',
          },

        ];

      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error:', err);
      },
      complete: () => {
        //  console.log('Request complete');
      }
    });
    // this.abnormalReadingData();
    this.dashboardService.dashboardabnormalReadingsData().subscribe(({
      next: (response) => {
        this.abnormalReadingList = response.data

        console.log(this.abnormalReadingList)
        this.rowDates = Array.from(new Set(
          this.abnormalReadingList.flatMap((item: { datewiseCounts: {}; }) => Object.keys(item.datewiseCounts))
        ));

        // Create the columns based on utility types
        this.columns = this.abnormalReadingList.map((item: { utilityType: any; }) => ({
          field: item.utilityType,
          title: item.utilityType
        }));

        // Create table rows, where each row represents a date, and each column is a utilityType
        this.abnoramReadingGridData = this.rowDates.map(date => {
          const row: any = { date };
          this.abnormalReadingList.forEach((item: any) => {
            row[item.utilityType] = item.datewiseCounts[date] || 0;  // Default to 0 if no data
          });
          return row;
        });
        //this.loaderService.setLoading(false);
        console.log(this.abnoramReadingGridData)
        if (this.abnoramReadingGridData.length > 0) {
          // console.error('Error:',);
          this.loaderService.setLoading(false);
        }
      }, error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error:', err);
      },
      complete: () => {
        //  console.log('Request complete');
      }
    }))



  }

  abnormalReadingData() {

  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  getwaynavigate(item: any) {
    // console.log(item)
    let gatewayStatus: any;
    switch (item.name) {

      case 'Gateways Communicating':
        gatewayStatus = "Yes"
        this.router.navigate(['/iotgateways'], {
          state: { Request: gatewayStatus },
        });

        break
      case 'Gateways Non Communicating':
        gatewayStatus = "No"
        this.router.navigate(['/iotgateways'], {
          state: { Request: gatewayStatus },
        });
        break;

      case 'Total Gateways':
      default:
        gatewayStatus = "Total"
        this.router.navigate(['/iotgateways'], {
          state: { Request: gatewayStatus },
        });
        break
    }
  }

  onCellClick(event: any) {
    const clickedDate = event.dataItem.date;  // Date of the clicked row
    const clickedUtility = event.column.title;  // Utility type of the clicked column
    const clickedValue = event.dataItem[clickedUtility]; // Value in the clicked cell

    //console.log(`Clicked on----------> ${clickedUtility} for ${clickedDate}: ${clickedValue}`);
    if (clickedUtility.toLowerCase() !== "date") {
      let Request =
      {
        "date": clickedDate,
        "utilityType": clickedUtility,
        'value': clickedValue
      }

      this.router.navigate(['/reports'], {
        state: { Request: Request },
      });
    }
    // You can perform further actions here (e.g., send data to a service or open a modal)
    //iotDevices

  }
  goToIotDevie(value: any) {
    let deviceSatus;
    if (value == "Total") {
      deviceSatus = value;
    } else {
      deviceSatus = value == 'Communicating' ? 'Yes' : 'No';
    }
    this.router.navigate(['/iotDevices'], {
      state: { Request: deviceSatus },
    });
  }
  goToIotDevieMeters() {
    this.router.navigate(['/iotDevices']);
  }

}



interface TableRow {
  utilityType: any; // This is explicitly defined as string
  [date: string]: number; // Index signature for the dynamic date properties (of type number)
}