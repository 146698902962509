import { createAction, props } from '@ngrx/store';
import { devices } from './iot-devices.models';

// devices creation
export const getDevices = createAction(
  '[device] getDevices',
  props<{
    pageIndex: number;
    pageSize: number|undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getDevicessSuccess = createAction(
  '[device] getDevices Success',
  props<{ getDevicesResponse: any }>()
);
export const getDevicessFailure = createAction(
  '[device] getDevices Failure',
  props<{ getDevicesError: any }>()
);

// all  device
export const GetAlldevice = createAction(
  '[device] getAlldevice',
  props<{
    pageIndex: number;
    pageSize: number|undefined;
    searchTerm?: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAlldevicesSuccess = createAction(
  '[device] GetAlldevice Success',
  props<{ getAlldeviceResponse: any }>()
);
export const getAlldevicesFailure = createAction(
  '[device] GetAlldevice Failure',
  props<{ getAlldeviceError: any }>()
);

// Create device
export const createdevice = createAction(
  '[device] Createdevice',
  props<{ deviceData: devices }>()
);
export const CreatedeviceSuccess = createAction(
  '[device] Createdevice Success',
  props<{ createdeviceResponse: any }>()
);
export const CreatedeviceFailure = createAction(
  '[device] Createdevice Failure',
  props<{ createdeviceError: any }>()
);

// Update device
export const updatedevice = createAction(
  '[device] Updatedevice',
  props<{ deviceData: devices; id: string }>()
);
export const updatedeviceSuccess = createAction(
  '[device] updateaster Success',
  props<{ updatedeviceResponse: any }>()
);
export const updatedeviceFailure = createAction(
  '[device] updatedevice Failure',
  props<{ updatedeviceError: any }>()
);

//delete device
export const deletedevice = createAction(
  '[device] deletedevice',
  props<{ id: string }>()
);
export const deletedeviceSuccess = createAction(
  '[device] deletedevice Success',
  props<{ deletedeviceResponse: any }>()
);
export const deletedeviceFailure = createAction(
  '[device] deletedevice Failure',
  props<{ deletedeviceError: any }>()
);

// reset store data
export const resetDevicesState = createAction(
  '[resetDevicesState ] Reset State'
);
