import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { devices } from '../../store/iot-devices/iot-devices.models';
import { detectIE } from '@progress/kendo-angular-grid/utils';

@Injectable({ providedIn: 'root' })
export class ReadingsService {
  constructor(private http: HttpClient, private router: Router) { }

  getreadings(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let skipValue, topValue;
    if (pageSize) {
      skipValue = pageIndex > 0 && pageSize ? (pageIndex - 1) * pageSize : 0;
      topValue = pageSize || 10000;
    }

    const url =
      `${AppConfigData.getReadingsUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize === undefined ? `&criteria=All` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}` +
      `${skipValue ? `&$skip=${skipValue}` : ''}` +
      `${topValue ? `&$top=${topValue}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getdevices failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  getMeterCommunicationHistory(
    macAddress: string = '',
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let skipValue, topValue;
    if (pageSize) {
      skipValue = pageIndex > 0 && pageSize ? (pageIndex - 1) * pageSize : 0;
      topValue = pageSize || 10000;
    }

    const url =
      `${AppConfigData.getMeterCommunicationHistory}?macAddress=${macAddress}` +
      `${pageIndex ? `&pageIndex=${pageIndex}` : ''}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize === undefined ? `&criteria=All` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}` +
      `${skipValue ? `&$skip=${skipValue}` : ''}` +
      `${topValue ? `&$top=${topValue}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getdevices failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getArchivedData(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let skipValue, topValue;
    if (pageSize) {
      skipValue = pageIndex > 0 && pageSize ? (pageIndex - 1) * pageSize : 0;
      topValue = pageSize || 10000;
    }
    const url =
      `${AppConfigData.getArchicedUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}` +
      `${skipValue ? `&$skip=${skipValue}` : ''}` +
      `${topValue ? `&$top=${topValue}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getdevices failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getrawData(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let skipValue, topValue;
    if (pageSize) {
      skipValue = pageIndex > 0 && pageSize ? (pageIndex - 1) * pageSize : 0;
      topValue = pageSize || 10000;
    }
    const url =
      `${AppConfigData.getRawDataUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}` +
      `${skipValue ? `&$skip=${skipValue}` : ''}` +
      `${topValue ? `&$top=${topValue}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getdevices failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getElectricStatusHistory(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.electricHistory}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getEb_Dg_History(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.Eb_Dg_History}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getRawData(
    id?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.getRawDataById + id}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getrawdata failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  getArchiveData(
    id?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.archived_data_by_id + id}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getrawdata failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }



  getIotData(
    id?: string
  ): Observable<any> {
    // let url;
    const url =
      `${AppConfigData.getIotDataById + id}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getiotdata failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
