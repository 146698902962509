import { SharedService } from './../../shared/shared.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { url } from 'inspector';
import { LocalStorageService } from './localStorage.service';
import { LoaderService } from './loader.service';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  ServiceError = Validations.ServiceError;
  constructor(private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService, private SharedService: SharedService,
    private localStorageService: LocalStorageService) { }





  exportReport(payload: any, reportType: string) {
    this.loaderService.setLoading(true)
    let url: string;
    let fileName: string;
    ///debugger
    //alert(reportType)
    // Determine the URL and filename based on the report type
    switch (reportType) {
     
      case 'deviceCommunication':
        url = AppConfigData.device_communication_report;
        fileName = 'deviceCommunication.xlsx';
        break;
        case 'AbnormalReading':
          url = AppConfigData.abonornal_report;
          fileName = 'AbnormalReading.xlsx';
          break;
      default:
        console.error('Invalid report type');
        return;
    }

    // Define the HTTP options for the request
    const httpOptions = {
      responseType: 'blob' as 'blob', // Specify the response type as 'blob'
      observe: 'response' as const    // Observe the entire response
    };

    // Make the HTTP POST request
    this.http.post(url, payload, httpOptions).subscribe(
      (response: any) => {
        this.loaderService.setLoading(false);
        console.log(response);
        
        // Check if the response is a JSON object with "No Data Found" message
        response.body.text().then((text: any) => {
          try {
            const jsonResponse = JSON.parse(text);
            if (jsonResponse && jsonResponse.message === 'No Data Found.') {
              this.SharedService.showMessageDialog(
                jsonResponse.message,
                '',
                SweetAlertIcon.INFO
              );
              return;
            }
          } catch {
            // Not JSON, proceed to download as blob
            const blob = new Blob([response.body], { type: 'application/octet-stream' });

            // Create a link element for the file download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // Set the download filename

            // Append the link to the body, click it, then remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Revoke the object URL after the download
            URL.revokeObjectURL(link.href);
          }
        });
      },
      (error) => {
        this.loaderService.setLoading(false);
        this.SharedService.showMessageDialog(
          error.error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.error('Error downloading the report:', error);
      }
    );
  }








}
