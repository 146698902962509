import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { deviceData } from './device-data';
import { LoaderService } from '../../../core/services/loader.service';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import {
  deletedeviceResponse,
  getAlldeviceResponse,
  getdeviceResponse,
} from '../../../store/iot-devices/iot-devices-selector';
import {
  deletedevice,
  GetAlldevice,
  getDevices,
  resetDevicesState,
} from '../../../store/iot-devices/iot-devices.actions';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { process, State, toODataString } from '@progress/kendo-data-query';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  ConfirmationModal,
  table_pageSize,
} from '../../../shared/modals/common';
import { Router } from '@angular/router';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import Swal from 'sweetalert2';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Templates } from '../../../shared/constants/templatesNames';
import { RpBulkUpoadComponent } from '../../../shared/components/rp-bulkupload/rp-bulkupload.component';
import { Helper } from '../../../core/helpers/helper';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-iot-devices',
  templateUrl: './iot-devices.component.html',
  styleUrl: './iot-devices.component.scss',
})
export class IotDevicesComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  modalRef!: BsModalRef;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('iotdevicesexport') public hiddenGrid: GridComponent | undefined;
  public gridData: unknown[] = deviceData;
  public gridView!: unknown[];

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public sort: SortDescriptor[] = [];
  public pageSize = 10;
  public skip = 0;
  totaldeviceCount: number = 0;
  currentPage: number = 1;
  allDevicesData: any = [];
  public devicegridData: GridDataResult = { data: [], total: 0 };
  selectableSettings = this.sharedService.selectableSettings;
  public devicestate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  breadCrumbItems: Array<{}> | undefined;
  searchedDeviceValue: string = '';
  queryString: string = '';
  orderByQuery: string = '';
  devicesList: any[] = [];
  mySelectiondevices: any = [];
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  dashboardFilterVlaue: any;
  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService
  ) {
    this.breadCrumbItems = [{ label: 'Iot Devices', url: 'iotDevices' }];

    if (history.state.Request != undefined && history.state.Request != null) {
      this.dashboardFilterVlaue = history.state.Request
    }
    this.getStoreInfo();
  }

  ngOnInit(): void {
    if (this.dashboardFilterVlaue && (this.dashboardFilterVlaue == "Yes" || this.dashboardFilterVlaue == "No")) {
      this.devicestate = {
        "skip": 0,
        "take": 10,
        "filter": {
          "filters": [
            {
              "filters": [
                {
                  "field": "communicationStatus",
                  "operator": "contains",
                  "value": this.dashboardFilterVlaue
                }
              ],
              "logic": "and"
            }
          ],
          "logic": "and"
        }
      }
      this.queryString = this.sharedService.createQuery(this.devicestate);
    }
    if (this.dashboardFilterVlaue && this.dashboardFilterVlaue === "Total") {
      this.devicestate = {
        "skip": 0,
        "take": 10,
        "filter": {
          "filters": [
            {
              "filters": [
                {
                  "field": "communicationStatus",
                  "operator": "contains",
                  "value": "Yes"
                },
                {
                  "field": "communicationStatus",
                  "operator": "contains",
                  "value": "No"
                }
              ],
              "logic": "or"
            }
          ],
          "logic": "and"
        }
      }
      this.queryString = this.sharedService.createQuery(this.devicestate);
    }


    // this.gridView = this.gridData;
    this.getDevices();


  }

  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  exportAllDevices() {
    this.store.dispatch(
      GetAlldevice({
        pageIndex: 1,
        pageSize: undefined,
      })
    );
  }


  navigateTocommunicationHistory(readings: any) {
    const serializedData = readings;
    this.router.navigate(['/iotDevices/communicationHistory'], {
      state: { readings: serializedData },
    });
  }
  getStoreInfo() {
    let getDevice$ = this.store.pipe(select(getdeviceResponse));
    let deletedeviceResponse$ = this.store.pipe(select(deletedeviceResponse));
    let getAlldeviceResponse$ = this.store.pipe(select(getAlldeviceResponse));
    // console.log(this.sendOtpResponse$);

    deletedeviceResponse$.subscribe((res: any) => {
      console.log(res);

      this.loaderService.setLoading(false);
      if (res) {
        this.getDevices();
      }
      console.log('delete', res);
    });
    getDevice$.subscribe((res: any) => {
      console.log(res);

      this.loaderService.setLoading(false);
      if (res) {
        this.devicesList = [...res.items];
        this.pageSize = res.pageSize;
        this.totaldeviceCount = res.totalCount;
      }
      this.loadDevices();
      console.log('devicesList', res, this.devicesList);
    });

    getAlldeviceResponse$.subscribe((exportData: any) => {
      let allDevicesData = [];
      if (exportData.items.length > 0) {
        this.allDevicesData = [...exportData.items];
        console.log('this.allDevicesData', this.allDevicesData);

        const transformedData = this.allDevicesData.map((item: any) => ({
          'Device Sr. No': item.deviceSRNo,
          'Device Id': item.deviceId,
          'Mac Address': item.macAddress,
          'Category': item.category?.name || '-',
          'Supplier Name': item.supplier?.name || '-',
          'Project Name': item.projectName,
          'Last Meter Reading Date': item.lastMeterReadingUpdatedDate
            ? new Date(item.lastMeterReadingUpdatedDate).toLocaleString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })
            : '-',
          'Response Configuration': item.responseConfiguration?.responseName || '-',
          'Communication Status': item.communicationStatus,
          'Year Of Make': item.yearOfMake || '-',
          'Monitoring Device': item.isMonitoringDevice ? 'Yes' : 'No',
          'Wired Communication Device': item.isWiredCommunicationDevice ? 'Yes' : 'No',
          'Gateway Address': item.gatewayInformation?.gatewayAddress || '-',
        }));

        this.PdfexportService.downloadExcel(transformedData, 'iot_Devices');
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.INFO
        );
      }
    });

  }

  deleteDevice(dataItem: any): void {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      // text: "You won't be able to revert this!",
      icon: SweetAlertIcon.ERROR,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        this.store.dispatch(deletedevice({ id: dataItem.id }));
        // Perform delete operation here
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sharedService.showMessageDialog(
          'Cancelled',
          '',
          SweetAlertIcon.INFO
        );
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.devicestate.sort = sort;
    console.log(this.devicestate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.devicestate.sort
    );

    this.orderByQuery = orderByQuery;
    this.getDevices();
  }


  openBulkUpdate() {
    let pageData = {
      title: Templates.IOT_DEVICE_BULK_UPDATE.id,
    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getDevices();
        // this.getResidentialUnitlist();
      }
      // Handle the received profile data here
    });
  }

  openBulkUpload() {
    let pageData = {
      title: Templates.IOT_DEVICE.id,
    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getDevices();
        // this.getResidentialUnitlist();
      }
      // Handle the received profile data here
    });
  }



  public clearSearchValueChangeDevices(ev: string): void {

    if (ev.length == 0) {
      this.devicePageChange({ skip: 0, take: this.pageSize })
    }
  }


  private loadDevices(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.devicesList, state);
    // this.devicegridData = {
    //   data: processedData.data,
    //   total: this.totaldeviceCount,
    // };
      const datePipe = new DatePipe('en-US'); // Create an instance of DatePipe
    this.devicegridData = {
      data: processedData.data.map((device: any) => ({
        ...device, // Spread existing device properties
        ismonitoringmodifiedvalue: device.isMonitoringDevice ? 'Yes' : 'No',// Add new key,
        lastMeterReadingUpdatedDate: datePipe.transform(device.lastMeterReadingUpdatedDate, 'dd MMM yyyy hh:mm a')
        // lastMeterReadingUpdatedDate:` ${Helper.excelformatDate(device.lastMeterReadingUpdatedDate)}`,
      })),
      total: this.totaldeviceCount
    };

  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.devicestate.filter = filter;
    console.log("this.devicestate-------->", this.devicestate)
    console.log(this.devicestate.filter);
    const queryString = this.sharedService.createQuery(this.devicestate);
    this.queryString = this.sharedService.createQuery(this.devicestate);
    this.getDevices();
    console.log(queryString);
  }

  protected devicePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getDevices();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectiondevices.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectiondevices.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isdeviceRowSelected(row.dataItem)) {
        this.mySelectiondevices.push(row.dataItem);
      }
    });

    console.log(this.mySelectiondevices);
  }

  public isdeviceRowSelected = (rowArg: any) => {
    return this.mySelectiondevices.some((row: any) => row.id === rowArg.id);
  };

  // public showTooltip(e: MouseEvent): void {
  //   const element = e.target as HTMLElement;

  //   // Check if the element is a table cell or has the class 'k-column-title'
  //   if (
  //     (element.nodeName === 'TD' && !element.closest('.no-tooltip')) ||
  //     element.classList.contains('k-column-title')
  //   ) {
  //     this.tooltipDir?.toggle(element);
  //   } else {
  //     this.tooltipDir?.hide();
  //   }
  // }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadDevices();
  }

  // public onFilter(value: Event): void {
  //   const inputValue = value;

  //   this.gridView = process(this.gridData, {
  //     filter: {
  //       logic: 'or',
  //       filters: [
  //         {
  //           field: 'ProjectCode',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'ProjectName',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'Region',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'Utility',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'ProjectType',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'ProjectStatus',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //       ],
  //     },
  //   }).data;

  //   this.dataBinding.skip = 0;
  // }

  public onFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedPayloadConfigValue = value;
    this.devicePageChange({ skip: 0, take: this.pageSize })
    // this.getDevices();
  }

  addNewIot() {
    this.router.navigate(['/iotDevices/createDevices'], {
      state: { type: 'Create' },
    });
  }


  ngOnDestroy() {
    let filter: CompositeFilterDescriptor = {
      filters: [],
      logic: 'and'
    };
    this.store.dispatch(resetDevicesState());
  }
  getDevices() {
    this.store.dispatch(
      getDevices({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedDeviceValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  editDevice(iotInfo: any) {
    const serializedData = JSON.stringify(iotInfo);
    this.router.navigate(['/iotDevices/createDevices'], {
      state: { deviceDetails: serializedData },
    });
  }
}
