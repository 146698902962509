<div class="container-fluid">
  <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
  <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">IOT DEVICES</h4>
            </div>
        </div>
    </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <section>
            <div class="row mb-2 d-sm-block d-md-none">
              <h4 class="card-title mb-4 text-decoration-underline">Gateway Information</h4>
            </div>

            <ng-template #template let-anchor>
              <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
              (mouseover)="showTooltip($event)">
              <kendo-grid [data]="gatewaygridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true" [pageable]="{
                        buttonCount: buttonCount,
                        info: info,
                        type: type,
                        pageSizes: pageSizes,
                        previousNext: previousNext
                      }" [reorderable]="true" filterable="menu" [filter]="gateWayState.filter"
                [skip]="gateWayState.skip" (filterChange)="onGateWayFilterChange($event)"
                (sortChange)="onGatewaySortChange($event)" [sort]="gateWayState.sort" [columnMenu]="{ filter: true }"
                [resizable]="true" scrollable="scrollable" [selectable]="selectableSettings"
                (pageChange)="gatewayPageChange($event)" (dataStateChange)="dataStateChange($event)"
                (selectionChange)="gatewayOnSelect($event)">


                <ng-template kendoGridToolbarTemplate>

                  <div class="search_icon">
                    <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="gateWaySearch"
                    [clearButton]="true"
                    (valueChange)="clearSearchValueChangeGateway($event)"  placeholder="Search in all columns...">
                    </kendo-textbox>

                    <button class="btn btn-primary btn-md border-left-none" (click)="ongatewayFilter($event)">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <kendo-grid-spacer></kendo-grid-spacer>

                  <button class="btn btn-outline-primary btn-sm me-2" (click)="addNewGateWay()">Add New
                  </button>
                  <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="onExport('Excel')">
                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                  </button>


                  <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="gatewayonExport()">
                              <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                </ng-template>
                <!-- Excel Export -->

                <!-- Pdf   start-->
                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                  <kendo-grid [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:gatewaygridData.data"
                    #pdfGrid>
                    
                  <!-- Gateway Address -->
                  <kendo-grid-column field="gatewayAddress" title="Gateway Address"></kendo-grid-column>
                  <kendo-grid-column field="communicationStatus" title="communication Status"></kendo-grid-column>
                  
                  <kendo-grid-column field="isWiredGateway" title="Is Wired GateWay">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span
                        [ngClass]="{'text-success': dataItem.isWiredGateway, 'text-danger': !dataItem.isWiredGateway}">
                        {{ dataItem.isWiredGateway ? 'Yes' : 'No' }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>


                    <!-- Active Status -->
                    <!-- <kendo-grid-column field="activeStatus" title="Gateway On/Off">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span
                          [ngClass]="{'text-success': dataItem.activeStatus, 'text-danger': !dataItem.activeStatus}">
                          {{ dataItem.activeStatus ? 'On' : 'Off' }}
                        </span>
                      </ng-template>
                    </kendo-grid-column> -->

                    <!-- Block Name -->
                    <kendo-grid-column field="blockName" title="Block Name"></kendo-grid-column>

                    <!-- Floor Number -->
                    <kendo-grid-column field="floorNum" title="Floor Number"></kendo-grid-column>


                    <!-- Network Status -->
                    <kendo-grid-column field="networkStatus" title="Network Status"></kendo-grid-column>

                    <!-- Project Name -->
                    <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>

                    <!-- Shaft -->
                    <kendo-grid-column field="shaft" title="Shaft"></kendo-grid-column>
                    <kendo-grid-pdf fileName="gateway.pdf" [allPages]="true" paperSize="A4"
                      [landscape]="true"></kendo-grid-pdf>
                    <kendo-grid-excel fileName="gateway.xlsx"></kendo-grid-excel>

                  </kendo-grid>
                </div>

                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                  [width]="40"></kendo-grid-checkbox-column>

                  

                  <!-- Gateway Address -->
                  <kendo-grid-column field="gatewayAddress" title="Gateway Address"></kendo-grid-column>
                  <kendo-grid-column field="communicationStatus" title="communication Status"></kendo-grid-column>
                  <kendo-grid-column field="isWiredGateway" title="Is Wired GateWay">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span
                        [ngClass]="{'text-success': dataItem.isWiredGateway, 'text-danger': !dataItem.isWiredGateway}">
                        {{ dataItem.isWiredGateway==true ? 'Yes' : 'No' }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>


                    <!-- Active Status -->
                    <!-- <kendo-grid-column field="activeStatus" title="Gateway On/Off">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span
                          [ngClass]="{'text-success': dataItem.activeStatus, 'text-danger': !dataItem.activeStatus}">
                          {{ dataItem.activeStatus ? 'On' : 'Off' }}
                        </span>
                      </ng-template>
                    </kendo-grid-column> -->

                  <!-- Block Name -->
                  <kendo-grid-column field="blockName" title="Block Name"></kendo-grid-column>

                  <!-- Floor Number -->
                  <kendo-grid-column field="floorNum" title="Floor Number"></kendo-grid-column>


                  <!-- Network Status -->
                  <kendo-grid-column field="networkStatus" title="Network Status"></kendo-grid-column>

                  <!-- Project Name -->
                  <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>

                  <!-- Shaft -->
                  <kendo-grid-column field="shaft" title="Shaft"></kendo-grid-column>

                <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="d-flex ">
                      <!-- Edit Gateway Icon -->
                      <span tooltip="Go to GateWay Edit" class="me-2" (click)="editGateWay(dataItem)"
                        style="cursor: pointer;">
                        <i class="fas fa-edit text-primary"></i>
                      </span>

                      <!-- Delete Gateway Icon -->
                      <!-- <span tooltip="Delete GateWay" class="ms-2" (click)="deleteGateway(dataItem)"
                        style="cursor: pointer;">
                        <i class="fas fa-trash-alt text-danger"></i>
                      </span> -->
                    </div>
                  </ng-template>
                </kendo-grid-command-column>

              </kendo-grid>
            </div>

          </section>
        </div>
      </div>
    </div>
  </div>

</div>