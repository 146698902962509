import { Component, ViewChild } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State, toODataString } from '@progress/kendo-data-query';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { LoaderService } from '../../core/services/loader.service';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../shared/shared.service';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Injectable } from '@angular/core';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../shared/modals/common';
import {
  getEb_Dg_Status,
  getAllElectricStatus,
} from '../../store/iot-readings/iot-readings.actions';
import {
  getAllElectricStatusResponse,
  getAllEb_Dg_StatusResponse,
} from '../../store/iot-readings/iot-readings-selector';
import {
  getAllEb_Dg_Status,
  getElectricStatus,
} from '../../store/iot-readings/iot-readings.actions';
import {
  getEb_Dg_StatusResponse,
  getElectricStatusResponse,
} from '../../store/iot-readings/iot-readings-selector';
@Component({
  selector: 'app-iot-electricity-status',
  templateUrl: './iot-electricity-status.component.html',
  styleUrl: './iot-electricity-status.component.scss',
})
export class IotElectricStuatsComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  electricStatussList: any = [];
  breadCrumbItems = [{ label: 'Electricity status' }];
  public electricStatusgridData: GridDataResult = { data: [], total: 0 };
  selectableSettings = this.sharedService.selectableSettings;

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public pageSize = 10;
  public eb_dg_statusPageSize = 10;
  public skip = 0;
  public eb_dg_statusskip = 0;
  totalelectricStatusCount: number = 0;
  currentPage: number = 1;
  eb_dg_statusCurrentPage: number = 1;

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;

  public electricStatusstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public eb_dg_statusstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  @ViewChild('electricStatusGrid') public electricStatushiddenGrid:
    | GridComponent
    | undefined;
  @ViewChild('eb_dg_statusGrid') public eb_dg_statushiddenGrid:
    | GridComponent
    | undefined;
  public sort: SortDescriptor[] = [];
  public eb_dg_statussgridData: GridDataResult = { data: [], total: 0 };
  eb_dg_statussList: any = [];
  totaleb_dg_statussCount: number = 0;
  mySelectionelectricStatuss: any = [];
  mySelectioneb_dg_status: any = [];
  queryString: string = '';
  orderByQuery: string = '';
  filterqueryString: string = '';
  eb_dg_statusOrderByQuery: string = '';
  searchedelectricStatusValue: string = '';
  searchedeb_dg_statusValue: string = '';
  allelectricStatussList: any[] = [];
  alleb_dg_statusList: any[] = [];
  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService
  ) {
    this.getStoreInfo();
    this.selectableSettings = this.sharedService.selectableSettings;
  }

  protected electricStatusPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1; // Calculate current page
    this.getelectricStatussData(); // Load data for the current page
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadelectricStatuss();
  }

  protected eb_dg_statusdataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadeb_dg_statuss();
  }

  private loadelectricStatuss(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.electricStatussList, state);
    this.electricStatusgridData = {
      data: processedData.data,
      total: this.totalelectricStatusCount,
    };
  }

  private loadeb_dg_statuss(): void {
    const state: State = {
      // skip: this.skip,
      take: this.eb_dg_statusPageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.eb_dg_statussList, state);
    // this.eb_dg_statussgridData = {
    //   data: this.eb_dg_statussList,
    //   total: this.totaleb_dg_statussCount,
    // };
    this.eb_dg_statussgridData = {
      data: processedData.data,
      total: this.totaleb_dg_statussCount,
    };
    console.log('this.eb_dg_statussgridData', this.eb_dg_statussgridData);
  }

  protected eb_dg_statusPageChange({ skip, take }: PageChangeEvent): void {
    this.eb_dg_statusskip = skip;
    this.eb_dg_statusPageSize = take;
    this.eb_dg_statusCurrentPage = Math.floor(skip / take) + 1; // Calculate current page
    this.geteb_dg_statussData(); // Load data for the current page
  }

  oneb_dg_statusFilter(value: any): void {
    // const inputValue = value;
    // this.searchedeb_dg_statusValue = value;
    this.eb_dg_statusPageChange({ skip: 0, take: this.eb_dg_statusPageSize })
    // this.geteb_dg_statussData();
  }

  public onelectricStatusFilter(value: any): void {
    // const inputValue = value;
    // this.searchedelectricStatusValue = value;
    this.electricStatusPageChange({ skip: 0, take: this.pageSize })
    // this.getelectricStatussData();
  }

  ngOnInit(): void {
    this.getAllConfigData();
    this.getelectricStatussData();
    this.geteb_dg_statussData();
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
      }
    });
  }

  getelectricStatussData() {
    this.store.dispatch(
      getElectricStatus({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedelectricStatusValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  getAllelectricStatussData() {
    this.store.dispatch(
      getAllElectricStatus({
        pageIndex: 1,
        pageSize: this.totalelectricStatusCount,
        searchTerm: this.searchedelectricStatusValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  geteb_dg_statussData() {
    this.store.dispatch(
      getEb_Dg_Status({
        pageIndex: this.eb_dg_statusCurrentPage,
        pageSize: this.eb_dg_statusPageSize,
        searchTerm: this.searchedeb_dg_statusValue,
        filter: this.filterqueryString,
        orderBy: this.eb_dg_statusOrderByQuery,
      })
    );
  }

  getAlleb_dg_statussData() {
    this.store.dispatch(
      getAllEb_Dg_Status({
        pageIndex: this.currentPage,
        pageSize: this.totaleb_dg_statussCount,
        searchTerm: this.searchedeb_dg_statusValue,
        filter: this.filterqueryString,
        orderBy: this.eb_dg_statusOrderByQuery,
      })
    );
  }

  getStoreInfo() {
    let getelectricStatuss$ = this.store.pipe(
      select(getElectricStatusResponse)
    );
    let getAllelectricStatusResponse$ = this.store.pipe(
      select(getAllElectricStatusResponse)
    );

    let geteb_dg_statuss$ = this.store.pipe(select(getEb_Dg_StatusResponse));
    let getAlleb_dg_statussResponse$ = this.store.pipe(
      select(getAllEb_Dg_StatusResponse)
    );
    // console.log(this.sendOtpResponse$);

    getelectricStatuss$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.electricStatussList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalelectricStatusCount = res.totalCount; // Ensure total count is updated correctly
      }
      this.loadelectricStatuss();
    });

    getAllelectricStatusResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.allelectricStatussList = [...res.items];
      }
      console.log('AllelectricStatussList', res, this.allelectricStatussList);
    });

    getAlleb_dg_statussResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.alleb_dg_statusList = [...res.items];
      }
      console.log('getAlleb_dg_statussResponse', res, this.alleb_dg_statusList);
    });

    geteb_dg_statuss$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.eb_dg_statussList = [...res.items];
        this.eb_dg_statusPageSize = res.pageSize;
        this.totaleb_dg_statussCount = res.totalCount; // Ensure total count is updated correctly
      }
      this.loadeb_dg_statuss();
    });
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.electricStatusstate.filter = filter;
    console.log(this.electricStatusstate);
    const queryString = this.sharedService.createQuery(
      this.electricStatusstate
    );
    this.queryString = this.sharedService.createQuery(this.electricStatusstate);
    this.getelectricStatussData();
    console.log(queryString);
  }
  onSortChange(sort: SortDescriptor[], type: string): void {
    if (type == 'electricStatus') {
      this.electricStatusstate.sort = sort;
      console.log(this.electricStatusstate.sort);
      const orderByQuery = this.sharedService.createOrderByQuery(
        this.electricStatusstate.sort
      );

      this.orderByQuery = orderByQuery;
      this.getelectricStatussData();
    } else {
      this.eb_dg_statusstate.sort = sort;
      console.log(this.electricStatusstate.sort);
      const orderByQuery = this.sharedService.createOrderByQuery(
        this.eb_dg_statusstate.sort
      );

      this.eb_dg_statusOrderByQuery = orderByQuery;
      this.geteb_dg_statussData();
    }
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell or has the class 'k-column-title'
    if (
      (element.nodeName === 'TD' && !element.closest('.no-tooltip')) ||
      element.classList.contains('k-column-title')
    ) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  oneb_dg_statusFilterChange(filter: CompositeFilterDescriptor): void {
    this.eb_dg_statusstate.filter = filter;
    console.log(this.eb_dg_statusstate);
    const queryString = this.sharedService.createQuery(this.eb_dg_statusstate);
    this.filterqueryString = this.sharedService.createQuery(
      this.eb_dg_statusstate
    );
    this.geteb_dg_statussData();
    console.log(queryString);
  }

  exportelectricStatus(type: string) {
    if (this.mySelectionelectricStatuss.length == 0) {
      this.getAllelectricStatussData();
    }
    if (type === 'Excel') {
      setTimeout(() => {
        this.electricStatushiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.electricStatushiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  exporteb_dg_status(type: string) {
    if (this.mySelectionelectricStatuss.length == 0) {
      this.getAlleb_dg_statussData();
    }
    if (type === 'Excel') {
      setTimeout(() => {
        this.eb_dg_statushiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.eb_dg_statushiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  public onelectricStatusPdfExport() {
    this.electricStatushiddenGrid?.saveAsPDF();
  }

  oneb_dg_statusPdfExport() {
    this.eb_dg_statushiddenGrid?.saveAsPDF();
  }
  public iselectricStatusRowSelected = (rowArg: any) => {
    return this.mySelectionelectricStatuss.some(
      (row: any) => row.id === rowArg.id
    );
  };

  public iseb_dg_statusRowSelected = (rowArg: any) => {
    return this.mySelectioneb_dg_status.some(
      (row: any) => row.id === rowArg.id
    );
  };

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionelectricStatuss.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionelectricStatuss.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.iselectricStatusRowSelected(row.dataItem)) {
        this.mySelectionelectricStatuss.push(row.dataItem);
      }
    });

    console.log(this.mySelectionelectricStatuss);
  }

  eb_dg_statusselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectioneb_dg_status.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectioneb_dg_status.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.iseb_dg_statusRowSelected(row.dataItem)) {
        this.mySelectioneb_dg_status.push(row.dataItem);
      }
    });

    console.log(this.mySelectioneb_dg_status);
  }


  public clearSearchValueChangeEbDgData(ev: string): void {
    if (ev.length == 0) {
      this.electricStatusPageChange({ skip: 0, take: this.pageSize })
    }
  }


  public clearSearchValueChangeOnOffData(ev: string): void {
    if (ev.length == 0) {
      this.eb_dg_statusPageChange({ skip: 0, take: this.eb_dg_statusPageSize })
    }
  }





}

// @Injectable()
// export class ProductsService extends NorthwindService {
//     constructor(http: HttpClient) {
//         super(http, 'Products');
//     }

// }
