import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../core/services/loader.service';
import { SharedService } from '../../shared/shared.service';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { log } from 'util';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportsService } from '../../core/services/reports.service';
import { Component, ViewChild } from '@angular/core';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-rp-reports',
  templateUrl: './rp-reports.component.html',
  styleUrl: './rp-reports.component.scss',
})
export class RpReportsComponent {
  @ViewChild('dateInput', { static: false }) datePicker!: BsDaterangepickerDirective;
  isFirstOpen: boolean = true;
  isopen: boolean = false;
  breadCrumbItems: Array<{}> | undefined;
  DeviceCommunicationnDateRange:any=[]
  settlemetDateRange:any=[]
  datePickerConfig: Partial<BsDatepickerConfig>;
  fromDate:any=[]
  validationForAbnormalReading: string='';
  validationforDeviceCommunication: string='';
  utilityList: any;
  selectedProject: any = [];
  DeviceCommunication_selectedUtility:any= ['all'];
  abnormal_selectedUtility:any= ['all'];
  configData:any;
  projectsList: any;
  projectsDupList: any;  
  ProjectValidationMessage: string = '';
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private reportService: ReportsService,
    private fb: FormBuilder
  ) {
    this.breadCrumbItems = [{ label: `Reports` }];
    const currentUserString = localStorage.getItem('currentUser');
    let currentUserData = JSON.parse(currentUserString ?? '');
  

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    
    this.datePickerConfig = Object.assign({}, {
      rangeInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue',
      minDate: oneMonthAgo, // Set to one month ago
      maxDate: today // Set to today
    });

    if (history.state.Request && history.state.Request.date) {
      console.log('history.state.Request', history.state.Request);  
      // Convert 'DD-MM-YYYY' string to Date
      const selectedDate = this.convertStringToDate(history.state.Request.date);
      this.abnormal_selectedUtility=[];
      this.abnormal_selectedUtility.push(history.state.Request.utilityType)
      // Set it as the default range (same date for start and end)
      if (selectedDate) {
        this.settlemetDateRange = [selectedDate, selectedDate];
        this.isFirstOpen = false;
        this.isopen = true;
      }
    }
  }

  onSelectProject(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.selectedProject = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.selectedProject.includes('all')) {
        this.selectedProject = this.selectedProject.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData.projects.length || 0;
        if (this.selectedProject.length === totalUtilityCount - 1) {
          this.selectedProject = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  convertStringToDate(dateStr: string): Date {
    if (!dateStr) return new Date(); // Fallback in case of invalid date
  
    const [day, month, year] = dateStr.split('-').map(Number);
  
    // Validate that parsed values are numbers and in correct range
    if (!day || !month || !year || month < 1 || month > 12 || day < 1 || day > 31) {
      console.error('Invalid date format:', dateStr);
      return new Date(); // Fallback to today's date
    }
  
    return new Date(year, month - 1, day); // Month is 0-based in JS
  }

  ngOnInit(): void {
    this.getAllConfigData();
  }


  formatDateRange(dateRange: (Date | null)[]): string {

    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/yyyy', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/yyyy', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  
  public onProjectFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectsList = this.projectsDupList.filter(contains(searchTerm));
  }

  openCalendarDeviceCommunication() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control date');
    dateInput?.dispatchEvent(new Event('click'));
    // if (this.datePicker) {
    //   this.datePicker.show();
    // }
  }

  openCalendarsettlement() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control settlement');
    dateInput?.dispatchEvent(new Event('click'));
  }
  clearDeviceCommunicationDate() {
    this.DeviceCommunicationnDateRange = [];
  }
  clearDateSettlement() {
    this.settlemetDateRange = [];
  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }
 
  ngAfterViewInit(){ 
    // if(history.state.Request){
    //   console.log('history.state.Request',history.state.Request);
    //   this.settlemetDateRange[0]= new Date(history.state.Request.date), // Set to one month ago
    //   this.settlemetDateRange[1]=new Date(history.state.Request.date) // Set to today
     
    // }
  }

  
  exportReport(type: string) {
    console.log(this.DeviceCommunication_selectedUtility);
    let fromDate: Date | null = null;
    let toDate: Date | null = null;
  
    // Validation message variables
    this.validationforDeviceCommunication = '';
    this.validationForAbnormalReading = '';
  
    let dateRange;
    let isValid = true;
    if (!this.selectedProject || this.selectedProject.length === 0) {
      this.ProjectValidationMessage = 'Please select at least one Project.';
      isValid = false;
    }

  
    if (type === 'deviceCommunication') {
      dateRange = this.DeviceCommunicationnDateRange;
    } else if (type === 'AbnormalReading') {
      dateRange = this.settlemetDateRange;
    }
  
    if (dateRange?.length > 0) {
      fromDate = new Date(dateRange[0].getTime() - dateRange[0].getTimezoneOffset() * 60000);
      toDate = new Date(dateRange[1].getTime() - dateRange[1].getTimezoneOffset() * 60000);
    } 
  
    // Set validation messages based on type
    if (!fromDate || !toDate) {
      if (type === 'deviceCommunication') {
        this.validationforDeviceCommunication = 'Please select a valid Date Range for Device Communication';
      } else if (type === 'AbnormalReading') {
        this.validationForAbnormalReading = 'Please select a valid Date Range for Abnormal Reading';
      }
      return;
    }
  
    let payload:any = {
      "startDate": fromDate.toISOString().slice(0, 10), // Extracting "YYYY-MM-DD"
      "endDate": toDate.toISOString().slice(0, 10),
    };
    if (type === 'deviceCommunication') {
      payload.projectNames= this.selectedProject
      if(this.selectedProject[0]=='all'){
        payload.projectNames = this.projectsList.map((x:any)=> x.name).slice(1);
      }else{
        payload.projectNames = this.selectedProject;
      }


      if(this.DeviceCommunication_selectedUtility[0]=='all'){
        payload.utilityTypes = this.utilityList.map((x:any)=> x.name).slice(1);
      }else{
        payload.utilityTypes = this.DeviceCommunication_selectedUtility;
      }
    } else if (type === 'AbnormalReading') {
      if(this.abnormal_selectedUtility[0]=='all'){
      payload.utilityTypes = this.utilityList.map((x:any)=> x.name).slice(1);
      }else{
        payload.utilityTypes = this.abnormal_selectedUtility;
      }
    }
    console.log(payload)
    this.reportService.exportReport(payload, type);
  }
  

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.configData.utilities = [
          {
            id: 'all',
            name: 'All Utilities',
          },
          ...(data.data?.utilities || []), // Use an empty array if data.data.utilities is undefined
        ];
        this.projectsList = [
          {
            id: 'all',
            name: 'All Projects',
          },
          ...this.configData?.projects,
        ];
        this.projectsDupList = [
          {
            id: 'all',
            name: 'All Projects',
          },
          ...this.configData?.projects,
        ];
        this.utilityList = data.data.utilities;
        this.selectedProject = [this.projectsList[0].id];
      }
    })
  }


  public onSelectUtilityInDeviceCommunication(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.DeviceCommunication_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.DeviceCommunication_selectedUtility.includes('all')) {
        this.DeviceCommunication_selectedUtility = this.DeviceCommunication_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.DeviceCommunication_selectedUtility.length === totalUtilityCount - 1) {
          this.DeviceCommunication_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }

  public onFilterUtilitiesChange(searchTerm: any): void {
    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    // Assuming configData.utilities is an array of utility objects

    this.utilityList = this.configData?.utilities.filter(contains(term)) || [];
  }

  onSelectUtilityInAbnormalReadings(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.abnormal_selectedUtility = ['all'];
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.abnormal_selectedUtility.includes('all')) {
        this.abnormal_selectedUtility = this.abnormal_selectedUtility.filter((item: any) => item !== 'all');
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.utilities.length || 0;
        if (this.abnormal_selectedUtility.length === totalUtilityCount - 1) {
          this.abnormal_selectedUtility = ['all']; // Select "All Utilities" if all individual utilities are selected
        }
      }
    }
  }



}
