import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private router: Router) {

  }

  getDashboardData(): Observable<any> {
    let url = `${AppConfigData.getDashboard}`
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        // Handle the error here
        console.error('Error fetching dashboard data:', error);

        // Return a new observable with a formatted error
        return throwError(() => new Error('Failed to load dashboard data. Please try again.'));
      })
    )
  }

  dashboardabnormalReadingsData(): Observable<any> {

    let url = `${AppConfigData.dashboardabnormalReadingsData}`
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;

      }), catchError((error) => {

        return throwError(() => new Error('Failed to load dashboard data. Please try again.'))
      })
    )
  }

}
