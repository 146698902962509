<div class="container-fluid">


  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">

          <button class="btn btn-primary d-flex align-items-center" (click)="exportAllDevices()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>

        </span>
      </div>
    </div>
  </div>

  <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">IOT DEVICES</h4>
            </div>
        </div>
    </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [data]="devicegridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
              [groupable]="false" [selectable]="selectableSettings" [pageable]="{
                                                        buttonCount: buttonCount,
                                                        info: info,
                                                        type: type,
                                                        pageSizes: pageSizes,
                                                        previousNext: previousNext
                                                      }" [reorderable]="true" filterable="menu"
              [filter]="devicestate.filter" [skip]="devicestate.skip" [sort]="devicestate.sort"
              [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
              (filterChange)="onFilterChange($event)" (sortChange)="onSortChange($event)"
              (pageChange)="devicePageChange($event)" (selectionChange)="selectionChanged($event)"
              (dataStateChange)="dataStateChange($event)">


              <ng-template kendoGridToolbarTemplate>
                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    (valueChange)="onFilter($event)"></kendo-textbox> -->
                <!-- <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedDeviceValue"
                                    placeholder="Search in all columns...">
                                </kendo-textbox>

                                <button class="btn btn-primary btn-md border-left-none" 
                                    (click)="onFilter($event)">
                                    <i class="fa fa-search"></i>
                                </button> -->

                <div>
                  <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedDeviceValue" [clearButton]="true"
                    (valueChange)="clearSearchValueChangeDevices($event)" placeholder="Search in all columns...">
                  </kendo-textbox>

                  <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUpdate()">Bulk Update </button>
                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUpload()">Bulk Upload </button>
                <button (click)="addNewIot()" [routerLink]="'iotDevices/createDevices'" class="btn btn-light"
                  kendoGridAddCommand>
                  <span class="fas fa-file-alt"> </span> &nbsp; Add IOT Devices </button>
                <!-- <button kendoGridPDFCommand class="btn btn-light">
                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                <button kendoGridExcelCommand class="btn btn-light" (click)="onExport('Excel')">
                  <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                </button>

              </ng-template>

              <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                class="no-tooltip"></kendo-grid-checkbox-column>
              <kendo-grid-column field="deviceSRNo" title="Device Sr. No"></kendo-grid-column>
              <kendo-grid-column field="deviceId" [hidden]="true" title="device Id"></kendo-grid-column>
              <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
              <kendo-grid-column field="category.name" title="Category"></kendo-grid-column>
              <kendo-grid-column field="supplier.name" title="Supplier Name"></kendo-grid-column>
              <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>
              <kendo-grid-column field="lastMeterReadingUpdatedDate" title="Last Meter Reading Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.lastMeterReadingUpdatedDate | date: 'dd MMM yyyy, hh:mm a' }}
                </ng-template>
              </kendo-grid-column>



              <kendo-grid-column field="responseConfiguration.responseName"
                title="Response Configuration"></kendo-grid-column>
              <kendo-grid-column field="yearOfMake" [hidden]="true" title="year Of Make"></kendo-grid-column>
              <kendo-grid-column field="communicationStatus" title="Communication Status"></kendo-grid-column>

              <kendo-grid-column field="isMonitoringDevice" title="Monitoring Device" filter="boolean">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.isMonitoringDevice ? 'Yes' : 'No' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="isWiredCommunicationDevice" title="is Wired CommunicationDevice"
                filter="boolean">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.isWiredCommunicationDevice ? 'Yes' : 'No' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="gatewayInformation.gatewayAddress" title="Gateway Address">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.gatewayInformation?.gatewayAddress || '-' }}
                </ng-template>
              </kendo-grid-column>




              <!-- <kendo-grid-column field="ProjectType" title="On Request Sent"></kendo-grid-column>
                        <kendo-grid-column field="ProjectStatus" title="Off Request Sent"></kendo-grid-column> -->
              <!-- <kendo-grid-command-column  [columnMenu]="false" [width]="150">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <button (click)="editDevice(dataItem)"
                                            class="btn btn-primary btn-sm d-flex align-items-center m-lg-auto ">
                                            Edit
                                        </button>
                                        <button (click)="deleteDevice(dataItem)"
                                            class="btn btn-light btn-sm d-flex align-items-center m-lg-auto ">
                                            Delete
                                        </button>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column> -->
              <kendo-grid-command-column [columnMenu]="false" [width]="140">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex">
                    <!-- Edit Button -->
                    <button kendoTooltip title="Go to Iot Device Edit"
                      class="btn btn-primary btn-sm d-flex align-items-center" (click)="editDevice(dataItem)">
                      <i class="fas fa-edit"></i>
                    </button>

                    <!-- Delete Button -->
                    <button kendoTooltip title="Delete Iot Device"
                      class="btn btn-danger btn-sm d-flex align-items-center m-lg-auto ms-3 p-2"
                      (click)="deleteDevice(dataItem)">
                      <i class="fas fa-trash-alt"></i>
                    </button>

                    <button class="btn btn-primary btn-sm m-lg-auto" kendoTooltip title="Communication History"
                      (click)="navigateTocommunicationHistory(dataItem)">
                      <i class="fas fa-info-circle"></i>
                    </button>
                  </div>


                </ng-template>
              </kendo-grid-command-column>



            </kendo-grid>
            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
              <kendo-grid
                [kendoGridBinding]="mySelectiondevices.length>0 ? mySelectiondevices:allDevicesData.length>0 ? allDevicesData:devicegridData.data"
                #iotdevicesexport>
                <kendo-grid-column field="deviceSRNo" title="Device Sr. No"></kendo-grid-column>
                <kendo-grid-column field="deviceId" title="device Id"></kendo-grid-column>
                <kendo-grid-column field="category.name" title="Category"></kendo-grid-column>
                <kendo-grid-column field="supplier.name" title="Supplier Name"></kendo-grid-column>
                <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>
                <kendo-grid-column field="lastMeterReadingUpdatedDate"
                  title="Last Meter Reading Date"></kendo-grid-column>
                <kendo-grid-column field="responseConfiguration.responseName"
                  title="Response Configuration"></kendo-grid-column>
                <kendo-grid-column field="yearOfMake" title="year Of Make"></kendo-grid-column>
                <kendo-grid-column field="communicationStatus" title="Communication Status"></kendo-grid-column>
                <kendo-grid-column field="ismonitoringmodifiedvalue" title="Monitoring Device" filter="boolean">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.isMonitoringDevice ? 'Yes' : 'No' }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="isWiredCommunicationDevice" title="is Wired CommunicationDevice"
                  filter="boolean">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.isWiredCommunicationDevice ? 'Yes' : 'No' }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="gatewayInformation.gatewayAddress"
                  title="Gateway Address"></kendo-grid-column>

                <kendo-grid-excel fileName="IotDevices.xlsx"></kendo-grid-excel>
              </kendo-grid>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>