<!-- Add Gateways modal -->

<!-- <div class="modal-dialog modal-dialog-centered"> -->
<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title"> {{ gatewayUpdateData?.id? 'Update Gateway' : 'Add Gateway' }} </h5>
        <button type="button" (click)="dismissModal()" class="btn-close text-white" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal" [formGroup]="creategatewayForm">
            <div class="mb-3">
                <label>Gateway Address <span class="required">*</span></label>
                <input type="text" class="form-control" formControlName="gatewayAddress" id="gatewayAddress" />
                @if(submitted && gatewayControls['gatewayAddress'].errors){
                    <div class="invalid-feedback">
                        @if(gatewayControls['gatewayAddress'].errors['required']){
                        <div>{{fileValidations.gateWayAddress.required}}</div>}
    
                        @if(gatewayControls['gatewayAddress'].errors['minlength']){
                        <div>{{fileValidations.gateWayAddress.min_required_Validations}}</div>
                        }
                        @if(gatewayControls['gatewayAddress'].errors['maxlength']){
                            <div>{{fileValidations.gateWayAddress.min_required_Validations}}</div>
                        }
                      
                    </div>}


            </div>
            <div class="mb-3">
                <label>project Name <span class="required">*</span></label>
                <ng-select [items]="listofProjects
                    " formControlName="projectId" (change)="valueChange($event)" [multiple]="false" bindLabel="name"
                    bindValue="id" [placeholder]="'Select...'"></ng-select>
                    @if(submitted && gatewayControls['projectId'].errors){
                        <div class="invalid-feedback">
                            @if(gatewayControls['projectId'].errors['required']){
                            <div>{{fileValidations.project.required}}</div>}                         
                          
                        </div>}
                
            </div>

          
                <div class="mb-3" style="margin-top: 30px;">
                    <label for="b">Is Wired Gateway<span
                            class="required">*</span></label>&nbsp;&nbsp;
                    <ui-switch [ngClass]="{
                    'switch-checked': creategatewayForm.controls['isWiredGateway'].value,
                    'switch-unchecked': !creategatewayForm.controls['isWiredGateway'].value
                  }" formControlName="isWiredGateway" [uncheckedLabel]="'No'"
                        [checkedLabel]="'Yes'" size="small" class="me-1" >
                    </ui-switch>
                </div>

            <div class="mb-3">
                <label>Shaft</label>
                <input type="text" class="form-control" formControlName="shaft" id="shaft" />
              
            </div>
            <div class="mb-3">
                <label>Block Name</label>
                <input type="text" class="form-control" formControlName="blockName" id="blockName" />
              
            </div>
            <div class="mb-3">
                <label>floor Num</label>
                <input type="text" class="form-control" formControlName="floorNum" id="floorNum" />
              
            </div>
            <div class="mb-3">
                <label>Network Status</label>
                <input type="text" class="form-control" formControlName="networkStatus" id="networkStatus" />
              
            </div>


        </form>
    </div>
    <div class="modal-footer">
        @if(gatewayUpdateData?.id){
        <button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="saveGateway('Update')">Update</button>}
        @else{
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveGateway('Create')">Save
            Gateway</button>}

        <!-- Add Gateways modal End  -->
    </div>
</div>
<!-- </div> -->