<div class="container-fluid">
    <div class="row">
        <div class="col-lg-10">
            <h4 class="mb-0 font-size-18">Welcome to Ripple IOT Dashboard!</h4>

        </div>
        <div class="col-lg-2">
            <button class="btn btn-primary fr" (click)="refreshDashboard()" kendoTooltip
                title="Clicking the 'Refresh' button will update the dashboard with the most recent information"
                [position]="'left'">
                <span><i class="fas fa-redo "></i> </span> &nbsp;&nbsp;Refresh
            </button>
            <!--  <h4 class="font-size-16">Project Start Date: 
            </h4>-->

        </div>
    </div>


    <div class="row">
        <div class="col-lg-4">
            <div class="position-relative d-flex justify-content-center">
                <!--  <div class="prepaid-dashboard-image">
                    <img src="../../../assets/images/wallet-balance.png">
                </div> -->
            </div>
            <div class="card mini-stats-wid mini-stats-wid-dashboard ">
                <div class="px-3 py-2">
                    <div class="card-details d-flex justify-content-center text-center mb-3"
                        (click)="goToIotDevie('Communicating')">
                        <div>
                            <p class="mb-0 mb-2 font-size-18 cursor-pointer">Meter Communicating &nbsp;<span
                                    class="text-danger font-size-10"></span>
                            <h4
                                class="font-weight-bold-dashboard text-truncate cursor-pointer mb-0  align-items-center">

                                {{this.dashboardData?.idCommCount !=undefined ? this.dashboardData?.idCommCount :''}}
                            </h4>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="position-relative d-flex justify-content-center">
                <!--  <div class="prepaid-dashboard-image">
                    <img src="../../../assets/images/wallet-balance.png">
                </div> -->
            </div>
            <div class="card mini-stats-wid mini-stats-wid-dashboard ">
                <div class="px-3 py-2">
                    <div class="card-details d-flex justify-content-center text-center mb-3"
                        (click)="goToIotDevie('NonCommunicating')">
                        <div class="text-center">
                            <p class="mb-0 mb-2 font-size-18 cursor-pointer"> Meter Non Communicating &nbsp;<span
                                    class="text-danger font-size-10"></span></p>
                            <h4
                                class="font-weight-bold-dashboard align-items-center   text-truncate mb-0 cursor-pointer">

                                {{dashboardData?.idNonCommCount!=undefined? dashboardData?.idNonCommCount:'' }}
                            </h4>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="position-relative d-flex justify-content-center">
                <!-- <div class="prepaid-dashboard-image">
                    <img src="../../../assets/images/wallet-balance.png">
                </div> -->
            </div>
            <div class="card mini-stats-wid mini-stats-wid-dashboard ">
                <div class="px-3 py-2">
                    <div class="card-details d-flex justify-content-center text-center mb-3"
                        (click)="goToIotDevie('Total')">
                        <div class="text-center">
                            <p class="mb-0 mb-2 font-size-18 cursor-pointer">Total Meters &nbsp;<span
                                    class="text-danger font-size-10"></span></p>
                            <h4 class="font-weight-bold-dashboard text-truncate mb-0  cursor-pointer ">
                                {{totalmeterdeviceCount !=undefined? totalmeterdeviceCount:''}}
                            </h4>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>


    <div class="row mt-3">
        <div class="col-xl-4" *ngFor="let item of gatewayCounts">
            <div class="card cursor-pointer" [style.background]="item.color" (click)="getwaynavigate(item)">
                <div class="text-center p-2">
                    <img [src]="item.img" class="w-20">
                    <div class="text-center">
                        <p class="mb-0">{{item.name}}</p>
                        <h4 class="mb-0">{{item.value}}</h4>
                    </div>
                    <!-- <div>
                            <p class="mb-0">{{item.labelAmount}}</p>
                            <h4 class="mb-0 text-truncate w-9" kendoTooltip title="{{item.amount}}">{{item.amount |
                                currency:
                                'INR':'symbol': '1.2-2'}}</h4>
                        </div> -->
                </div>
                <!-- <div *ngIf="gatewayCounts.length==0" class="chart-loader">
                        <div class="spinner"></div>
                    </div> -->
            </div>
        </div>
    </div>

    <!-- table -->
    <div class="row page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="col-12">
            <h4 class="mb-0 font-size-18">Meters Gateway abnormal readings</h4>
        </div>
    </div>
    <section>
        <kendo-grid [data]="abnoramReadingGridData" (cellClick)="onCellClick($event)" id="tableGrid">
            <kendo-grid-column field="date" title="Date" [headerStyle]="{'text-align': 'center'}"
                [style]="{'text-align': 'center'}" [headerClass]="'headerCustomClass'"></kendo-grid-column>

            <!-- Dynamically generate columns for each utilityType -->
            <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title"
                [headerClass]="'headerCustomClass'">
            </kendo-grid-column>
        </kendo-grid>

    </section>


</div>