import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { readingData } from './reading-data';
import { LoaderService } from '../../../core/services/loader.service';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import {
  getArchivedResponse,
  getrawDataSuccessResponse,
  getreadingsResponse,
} from '../../../store/iot-readings/iot-readings-selector';
import { process, State, toODataString } from '@progress/kendo-data-query';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import {
  getreadings,
  getArchivedData,
  GetrawData,
  resetreadingsState,
} from '../../../store/iot-readings/iot-readings.actions';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../../shared/modals/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {
  getAllreadings,
  getAllArchivedData,
} from '../../../store/iot-readings/iot-readings.actions';
import {
  getAllreadingsResponse,
  getAllArchivedResponse,
} from '../../../store/iot-readings/iot-readings-selector';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { Subscription } from 'rxjs';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Helper } from '../../../core/helpers/helper';
import {
  GetAllrawData,
  resetExportedData,
} from '../../../store/iot-readings/iot-readings.actions';
import { GetAllrawDataSuccessResponse } from '../../../store/iot-readings/iot-readings-selector';
import { CdkStepper } from '@angular/cdk/stepper';
import { FilterOperator } from '@progress/kendo-angular-grid/common/filter-operator.interface';
import { DatePipe } from '@angular/common';
import { log } from 'console';
@Component({
  selector: 'app-iot-reading',
  templateUrl: './iot-reading.component.html',
  styleUrl: './iot-reading.component.scss',
})
export class IotReadingComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  selectValue!: string[];
  hidden!: boolean;
  selected: any;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  @Input() fromDate!: Date;
  @Input() toDate!: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

  @ViewChild('dp', { static: true }) datePicker: any;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  public gridData: unknown[] = readingData;
  public gridView!: unknown[];
  breadCrumbItems: Array<{}> | undefined;
  readingsList: any[] = [];
  reading_pageSize: number = 10;
  totalReadingsCount: number = 0;
  public readingsstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public sort: SortDescriptor[] = [];
  public readings_filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public readingsgridData: GridDataResult = { data: [], total: 0 };
  


  public skip = 0;
  public archivedSkip = 0;
  public rawSkip = 0;
  totaldeviceCount: number = 0;
  readingcurrentPage: number = 1;
  selectableSettings = this.sharedService.selectableSettings;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  readingQueryString: string = '';
  readingsorderByQuery: string = '';
  readingsSearchValue: string = '';
  mySelectionReadings: any = [];
  archivedList: any[] = [];
  archived_pageSize: number = 10;
  totalArchivedCount: number = 0;
  public archived_filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public archivedgridData: GridDataResult = { data: [], total: 0 };
  public archivedstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  archivedcurrentPage: number = 1;
  archivedSearchValue: string = '';
  archivedQueryString: string = '';
  archivedorderByQuery: string = '';
  myArchivedSelectionReadings: any = [];

  rawDataList: any[] = [];
  rawData_pageSize: number = 10;
  totalrawDataCount: number = 0;
  selectedDateRange: Date[] | undefined;
  public rawData_filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public rawDatagridData: GridDataResult = { data: [], total: 0 };
  public rawDatastate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  rawDatacurrentPage: number = 1;
  rawDataSearchValue: string = '';
  rawDataQueryString: string = '';
  rawDataorderByQuery: string = '';
  myrawDataSelectionReadings: any = [];
  public filteredData: any[] = this.gridData;

  datePickerConfig: Partial<BsDatepickerConfig>;
  @ViewChild('exportiotreadings') public hiddenGrid: GridComponent | undefined;
  @ViewChild('exportArchived') public hiddenArchivedGrid:
    | GridComponent
    | undefined;

  @ViewChild('hiddenRawGrid') public hiddenRawGrid: GridComponent | undefined;
  allReadingsData: any = [];
  selectedIndex: number = 0;
  constructor(
    private loaderService: LoaderService,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService
  ) {
    this.breadCrumbItems = [{ label: 'Iot Readings', url: 'iotReading' }];
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.getStoreInfo();
  }

  onDateChange(selectedDates: (Date | undefined)[] | undefined): void {
    if (selectedDates && selectedDates.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];

      if (startDate && endDate) {
        // Adjust the start and end dates to remove the timezone offset
        const adjustedStartDate = new Date(
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )
        );
        const adjustedEndDate = new Date(
          Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
        );

        // Format dates to ISO format (yyyy-MM-ddTHH:mm.sssZ) in UTC
        const formattedStartDate = adjustedStartDate.toISOString();
        const formattedEndDate = adjustedEndDate.toISOString();

        // Construct the filter query in the required format
        const filterQuery = `$filter=(date_Time ge ${formattedStartDate} and date_Time le ${formattedEndDate})`;

        console.log('Filter Query:', filterQuery);
        this.readingQueryString = filterQuery;

        // You can now use this filter query to apply to your data or send it to an API
      } else {
        console.log('Invalid date range');
      }
    } else {
      console.log('No valid dates selected');
    }
  }
  filterReadings(type: string) {
    if (type != 'filter') {
      this.selectedDateRange = undefined;
      this.readingQueryString = '';
      this.getreadings();
    } else {
      this.getreadings();
    }
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  onReadingsExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  onArchivedExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenArchivedGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenArchivedGrid?.saveAsPDF();
      }, 1000);
    }
  }

  onRawExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenRawGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenRawGrid?.saveAsPDF();
      }, 1000);
    }
  }
  private exportAllReadingsSubscription: Subscription = new Subscription();
  private exportAllArchievedSubscription: Subscription = new Subscription();
  private exportAllRawDataSubscription: Subscription = new Subscription();
  getStoreInfo() {
    let getreadingsResponse$ = this.store.pipe(select(getreadingsResponse));
    let getArchivedResponse$ = this.store.pipe(select(getArchivedResponse));
    let getrawDataSuccessResponse$ = this.store.pipe(
      select(getrawDataSuccessResponse)
    );

    this.exportAllReadingsSubscription = this.store
      .pipe(select(getAllreadingsResponse))
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.setLoading(false);
          this.allReadingsData = [];
          if (res.items.length > 0) {
            this.allReadingsData = [...res.items];
            const transformedData = this.allReadingsData.map((item: any) => ({
              'Utility Type': item.utilityType,
              'Device Sr. No': item.devicesSRNO,
              'Monitoring Device': item.isMonitoringDevice, // Placeholder for region.name
              'Date & time': Helper.excelformatDate(item.date_Time), // Placeholder for bill date
              'Meter Reading': item.meterReading, // Placeholder for status
              'Meter Reading EB': item.meterReadingEB, // Placeholder for status
              'Meter Reading DG': item.meterReadingDG, // Placeholder for status
              'Meter Reading TS': item.meterReadingTS, // Placeholder for status
              'On / off status': item.onOffStatus,
              'Project name': item.projectName, // Placeholder for projectType
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Readings');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    this.exportAllArchievedSubscription = this.store
      .pipe(select(getAllArchivedResponse))
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.setLoading(false);
          this.allReadingsData = [];
          if (res.items.length > 0) {
            this.allReadingsData = [...res.items];
            const transformedData = this.allReadingsData.map((item: any) => ({
              'SR. NO': item.deviceAddress,
              Utility: item.utility,
              'Date & time': Helper.excelformatDate(item.receivedTime), // Placeholder for bill date
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Archived');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    this.exportAllRawDataSubscription = this.store
      .pipe(select(GetAllrawDataSuccessResponse))
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.setLoading(false);
          this.allReadingsData = [];
          if (res.items.length > 0) {
            this.allReadingsData = [...res.items];
            const transformedData = this.allReadingsData.map((item: any) => ({
              'SR. NO': item.deviceAddress,
              Utility: item.utility,
              'Date & time': Helper.excelformatDate(item.receivedTime), // Placeholder for bill date
            }));
            this.PdfexportService.downloadExcel(transformedData, 'RawData');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getreadingsResponse$.subscribe((res: any) => {
      console.log(res);
      this.loaderService.setLoading(false);
      if (res) {
        this.readingsList = [...res.items];
        this.reading_pageSize = res.pageSize;
        this.totalReadingsCount = res.totalCount;
      }
      this.loadReadings();
      console.log('devicesList', res, this.readingsList);
    });

    getrawDataSuccessResponse$.subscribe((res: any) => {
      console.log(res);
      this.loaderService.setLoading(false);
      if (res) {
        this.rawDataList = [...res.items];
        this.rawData_pageSize = res.pageSize;
        this.totalrawDataCount = res.totalCount;
      }
      this.loadRawData();
      console.log('devicesList', res, this.readingsList);
    });

    getArchivedResponse$.subscribe((res: any) => {
      console.log(res);
      this.loaderService.setLoading(false);
      if (res) {
        this.archivedList = [...res.items];
        this.archived_pageSize = res.pageSize;
        this.totalArchivedCount = res.totalCount;
      }
      this.loadArchived();
      console.log('devicesList', res, this.readingsList);
    });
  }
  public onFilter(value: Event): void {
    const inputValue = value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ProjectCode',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Region',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Utility',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectType',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectStatus',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  private loadReadings(): void {
    const state: State = {
      take: this.reading_pageSize,
      sort: this.sort,
      filter: this.readings_filter,
    };

    const processedData = process(this.readingsList, state);
    this.readingsgridData = {
      data: processedData.data,
      total: this.totalReadingsCount,
    };

    const datePipe = new DatePipe('en-US'); // Create an instance of DatePipe
    this.readingsgridData.data = this.readingsgridData?.data.map((data) => {
      return {
        ...data,
        date_Time: datePipe.transform(data.date_Time, 'dd MMM yyyy hh:mm a')
      };
    });


  }
  private loadArchived(): void {
    const state: State = {
      take: this.archived_pageSize,
      sort: this.sort,
      filter: this.archived_filter,
    };

    const processedData = process(this.archivedList, state);
    this.archivedgridData = {
      data: processedData.data,
      total: this.totalArchivedCount,
    };

    const datePipe = new DatePipe('en-US'); // Create an instance of DatePipe
    this.archivedgridData.data = this.archivedgridData?.data.map((data) => {

      console.log('1111111111111',data)
      return {
        ...data,
        receivedTime: datePipe.transform(data.receivedTime, 'dd MMM yyyy hh:mm a')
      };
    });
  }

  private loadRawData(): void {
    const state: State = {
      take: this.rawData_pageSize,
      sort: this.sort,
      filter: this.rawData_filter,
    };

    const processedData = process(this.rawDataList, state);
    this.rawDatagridData = {
      data: processedData.data,
      total: this.totalrawDataCount,
    };
    const datePipe = new DatePipe('en-US'); // Create an instance of DatePipe
    this.rawDatagridData.data = this.rawDatagridData?.data.map((data) => {
      return {
        ...data,
        receivedTime: datePipe.transform(data.receivedTime, 'dd MMM yyyy hh:mm a')
      };
    });
  }

  ngOnInit(): void {
    this.gridView = this.gridData;
 
    this.getreadings();
    // this.getArchived();
    // this.getRawData();
  }
  ngAfterViewInit(): void {
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use
    }
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.readingsstate.filter = filter;
    console.log(this.readingsstate);
    this.readingQueryString = this.sharedService.createQuery(
      this.readingsstate
    );
    this.getreadings();
    console.log(this.readingQueryString);
  }

  public onArchivedFilterChange(filter: CompositeFilterDescriptor): void {
    this.archivedstate.filter = filter;
    console.log(this.archivedstate);
    this.archivedQueryString = this.sharedService.createQuery(
      this.archivedstate
    );
    this.getArchived();
    console.log(this.archivedQueryString);
  }

  public onRawDataFilterChange(filter: CompositeFilterDescriptor): void {
    this.rawDatastate.filter = filter;
    console.log(this.rawDatastate);
    this.rawDataQueryString = this.sharedService.createQuery(this.rawDatastate);
    this.getRawData();
    console.log(this.rawDataQueryString);
  }

  getreadings() {
    this.store.dispatch(
      getreadings({
        pageIndex: this.readingcurrentPage,
        pageSize: this.reading_pageSize,
        searchTerm: this.readingsSearchValue,
        filter: this.readingQueryString,
        orderBy: this.readingsorderByQuery,
      })
    );
  }

  getAllreadings() {
    this.store.dispatch(
      getAllreadings({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  getArchived() {
    this.store.dispatch(
      getArchivedData({
        pageIndex: this.archivedcurrentPage,
        pageSize: this.archived_pageSize,
        searchTerm: this.archivedSearchValue,
        filter: this.archivedQueryString,
        orderBy: this.archivedorderByQuery,
      })
    );
  }

  getAllArchived() {
    this.store.dispatch(
      getAllArchivedData({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }
  getRawData() {
    this.store.dispatch(
      GetrawData({
        pageIndex: this.rawDatacurrentPage,
        pageSize: this.rawData_pageSize,
        searchTerm: this.rawDataSearchValue,
        filter: this.rawDataQueryString,
        orderBy: this.rawDataorderByQuery,
      })
    );
  }

  getAllRawData() {
    this.store.dispatch(
      GetAllrawData({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  onStepChange(event: any): void {
    this.selectedIndex = event.selectedIndex;
    if(this.selectedIndex == 0){
      this.getreadings();
      // this.getArchived();
      // this.getRawData();
    }else if (this.selectedIndex == 1){
      this.getArchived();
    }else{
      this.getRawData();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(resetExportedData());
    this.store.dispatch(resetreadingsState());
  }
  exportAll() {
    if (this.selectedIndex == 0) {
      this.getAllreadings();
    } else if (this.selectedIndex == 1) {
      this.getAllArchived();
    } else if (this.selectedIndex == 2) {
      this.getAllRawData();
    }
  }

  public readingsFilter(value: any): void {
    // this.readingsSearchValue = value;
    this.devicePageChange({ skip:0, take:this.reading_pageSize })
    // this.getreadings();
  }
  public archivedFilter(value: any): void {
    // this.archivedSearchValue = value;
    this.archivedDevicePageChange({ skip:0, take:this.archived_pageSize })
    // this.getArchived();
  }

  public rawFilter(value: any): void {
    // this.archivedSearchValue = value;
    this.rawDataPageChange({ skip:0, take:this.rawData_pageSize })
    // this.getRawData();
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.readingsstate.sort = sort;
    console.log(this.readingsstate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.readingsstate.sort
    );

    this.readingsorderByQuery = orderByQuery;
    this.getreadings();
  }

  onArchivedSortChange(sort: SortDescriptor[]): void {
    this.archivedstate.sort = sort;
    console.log(this.archivedstate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.archivedstate.sort
    );

    this.archivedorderByQuery = orderByQuery;
    this.getArchived();
  }

  onRawDataSortChange(sort: SortDescriptor[]): void {
    this.rawDatastate.sort = sort;
    console.log(this.rawDatastate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.rawDatastate.sort
    );

    this.rawDataorderByQuery = orderByQuery;
    this.getRawData();
  }

  protected devicePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.reading_pageSize = take;
    this.readingcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getreadings();
  }
  protected archivedDevicePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.archivedSkip = skip;
    this.archived_pageSize = take;
    this.archivedcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getArchived();
  }

  protected rawDataPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.rawSkip = skip;
    this.rawData_pageSize = take;
    this.rawDatacurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getRawData();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionReadings.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionReadings.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isdeviceRowSelected(row.dataItem)) {
        this.mySelectionReadings.push(row.dataItem);
      }
    });

    console.log(this.mySelectionReadings);
  }

  public archivedselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.myArchivedSelectionReadings.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.myArchivedSelectionReadings.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isArchiveddeviceRowSelected(row.dataItem)) {
        this.myArchivedSelectionReadings.push(row.dataItem);
      }
    });

    console.log(this.myArchivedSelectionReadings);
  }
  public isArchiveddeviceRowSelected = (rowArg: any) => {
    return this.myArchivedSelectionReadings.some(
      (row: any) => row.id === rowArg.id
    );
  };

  public rawDataSelectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.myrawDataSelectionReadings.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.myrawDataSelectionReadings.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isArchivedRowSelected(row.dataItem)) {
        this.myrawDataSelectionReadings.push(row.dataItem);
      }
    });

    console.log(this.myrawDataSelectionReadings);
  }
  public isArchivedRowSelected = (rowArg: any) => {
    return this.myrawDataSelectionReadings.some(
      (row: any) => row.id === rowArg.id
    );
  };

  public isdeviceRowSelected = (rowArg: any) => {
    return this.mySelectionReadings.some((row: any) => row.id === rowArg.id);
  };
  protected dataStateChange(state: DataStateChangeEvent): void {
    console.log(state);
    
    this.sort = state.sort || [];
    this.reading_pageSize = state.take;
    // this.readings_filter = state.filter || { logic: 'and', filters: [] };
    // this.getreadings();
  }
  protected archivedDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.archived_pageSize = state.take;
    // this.archived_filter = state.filter || { logic: 'and', filters: [] };
    // this.getArchived();
  }
  protected rawDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.rawData_pageSize = state.take;
    // this.rawData_filter = state.filter || { logic: 'and', filters: [] };
    // this.getRawData();
  }
  navigateToViewReadings(readings: any, type: string) {
    if (this.stepper) {
      localStorage.setItem('selectedTabIndex', this.stepper.selectedIndex.toString());
    }
    const serializedData = JSON.stringify(readings);
    this.router.navigate(['/iotReading/viewDetails'], {
      state: { readings: serializedData, type: type },
    });
  }

  public clearSearchValueChangeIotReadings(ev: string): void {
    if (ev.length == 0) {
      this.devicePageChange({ skip:0, take:this.reading_pageSize })
    }
  }

  
  public clearSearchValueChangeArchiveReadings(ev: string): void {
    if (ev.length == 0) {
      this.archivedDevicePageChange({ skip:0, take:this.archived_pageSize })
    }
  }

  public clearSearchValueChangeRawData(ev: string): void {
    if (ev.length == 0) {
      this.rawDataPageChange({ skip:0, take:this.rawData_pageSize })
    }
  }

  
}
