<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn  btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;All Export to Excel
                    </button>
                </span>
            </div>
        </div>
    </div>



    <section class="">
        <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [data]="communicationHistorygridData" [pageSize]="reading_pageSize" [skip]="skip"
                [sortable]="true" [groupable]="false" [selectable]="selectableSettings" [pageable]="{
                                                  buttonCount: buttonCount,
                                                  info: info,
                                                  type: type,
                                                  pageSizes: pageSizes,
                                                  previousNext: previousNext
                                                }" [reorderable]="true" filterable="menu"
                [filter]="readingsstate.filter" [sort]="readingsstate.sort" [columnMenu]="{ filter: true }"
                [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
                (filterChange)="onFilterChange($event)" (sortChange)="onSortChange($event)"
                (pageChange)="devicePageChange($event)" (selectionChange)="selectionChanged($event)"
                (dataStateChange)="dataStateChange($event)" style="width: 100%;" scrollable="scrollable">
                <ng-template kendoGridToolbarTemplate>
                    <div>
                        <kendo-textbox [style.width.px]="250" [(ngModel)]="readingsSearchValue" [clearButton]="true"
                            (valueChange)="clearSearchValueChangeDevices($event)"
                            placeholder="Search in all columns...">
                        </kendo-textbox>

                        <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                    <kendo-grid-spacer></kendo-grid-spacer>
                    <button kendoGridExcelCommand class="btn btn-light" (click)="onReadingsExport('Excel')">
                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                    </button>

                </ng-template>

                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                    class="no-tooltip"></kendo-grid-checkbox-column>

                    
                    <kendo-grid-column [width]="100" field="devicesSRNO" title="Devices SRNO"></kendo-grid-column>
                <kendo-grid-column [width]="100" field="devicesAddress" title="Mac Address">
                </kendo-grid-column>
                <kendo-grid-column [width]="100" field="communicationDate" title="Communication Date" filter="date">

                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.communicationDate =='' ?'':
                        dataItem.communicationDate |
                        date: 'dd MMM yyyy hh:mm a'}}

                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [width]="100" field="communicationsStatus" title="Communication Status">
                </kendo-grid-column>


            </kendo-grid>
            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                <kendo-grid
                    [kendoGridBinding]="mySelectionReadings.length>0 ? mySelectionReadings:communicationHistorygridData.data"
                    #exportiotreadings>
                    
                    <kendo-grid-column [width]="100" field="devicesSRNO" title="Devices SRNO"></kendo-grid-column>

                    <kendo-grid-column [width]="100" field="devicesAddress" title="Mac Address">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="communicationDate" title="Communication Date">
                    </kendo-grid-column>

                    <kendo-grid-column [width]="100" field="communicationsStatus" title="communicationStatus">
                    </kendo-grid-column>



                    <kendo-grid-excel fileName="communications History.xlsx"></kendo-grid-excel>
                </kendo-grid>

            </div>
        </div>

    </section>

</div>