import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { MENU } from '../../layouts/rp-sidebar/menu';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;
    localStorage.getItem('currentUser');
    const url = state.url.split('?')[0];
    console.log(url);
    if (!currentUser) {
      this.router.navigate(['/auth/login']);
      return false;
    } else if (url == '/') {
      // this.router.navigate(['/dashboard']);
      this.router.navigate(['/dashboard']);
    }

    // Additional logic can be added here if needed, for example, role-based checks
    // or other route-specific authentication logic.

    return true;
  }
}
