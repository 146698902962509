import { Component, ViewChild } from '@angular/core';
import { getAllCommnicationHistory, getCommnicationHistory, reseExportCommnicationHistorytState } from '../../../store/iot-readings/iot-readings.actions';
import { LoaderService } from '../../../core/services/loader.service';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { CompositeFilterDescriptor, process, SortDescriptor, State, toODataString } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { PdfexportService } from '../../../core/services/pdfexport.service';

import { CommnicationHistoryResponse, getAllCommnicationHistoryResponse } from '../../../store/iot-readings/iot-readings-selector';
import items from 'razorpay/dist/types/items';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { subreportIcon } from '@progress/kendo-svg-icons';
import { SweetAlertIcon } from '../../../shared/constants/enum';

@Component({
  selector: 'app-iot-communication-history',
  templateUrl: './iot-communication-history.component.html',
  styleUrl: './iot-communication-history.component.scss'
})
export class IotCommunicationHistoryComponent {
  breadCrumbItems: Array<{}> | undefined;

  reading_pageSize: number = 10;
  totalReadingsCount: number = 0;
  public readingsstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public readings_filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  private exportSubscription: Subscription = new Subscription();
  exportdata: any[] = []
  mySelectionReadings: any = [];
  selectableSettings = this.sharedService.selectableSettings;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public communicationHistorygridData: GridDataResult = { data: [], total: 0 };
  readingcurrentPage: number = 1;
  readingQueryString: string = '';
  readingsorderByQuery: string = '';
  readingsSearchValue: string = '';
  macAddress: string = ''
  @ViewChild('exportiotreadings') public hiddenGrid: GridComponent | undefined;
  communicationhistoryList: any[] = []
  constructor(private loaderService: LoaderService,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService
  ) {

    this.breadCrumbItems = [{ label: 'Iot Devices', url: 'iotDevices', active: true },
    { label: 'Communication History' },];
    if (history.state.readings != undefined && history.state.readings != null) {
      this.macAddress = history.state.readings?.macAddress
      console.log(history.state.readings)

      this.getStoreInfo();

    }
  }
  getStoreInfo() {
    let geCommnicationHistoryResponse$ = this.store.pipe(select(CommnicationHistoryResponse));
    geCommnicationHistoryResponse$.subscribe(({
      next: (res) => {
        console.log(res)
        this.communicationhistoryList = [...res.items];
        this.reading_pageSize = res.pageSize;
        this.totalReadingsCount = res.totalCount;
        this.loadReadings();

      }, error: (err) => {

        console.log(err);


      },
    }))




  }

  ngOnDestroy() {
    this.exportSubscription.unsubscribe();

  }
  private loadReadings(): void {
    const state: State = {
      take: this.reading_pageSize,
      sort: this.sort,
      filter: this.readings_filter,
    };

    const processedData = process(this.communicationhistoryList, state);
    this.communicationHistorygridData = {
      data: processedData.data,
      total: this.totalReadingsCount,
    };
    this.communicationHistorygridData.data = this.communicationHistorygridData.data.map((items: any) => {

      return {
        ...items,
        communicationDate: items?.communicationDate != null ? this.sharedService.dateFormateChnage(items.communicationDate) : '-'
      }

    })


  }


  ngOnInit(): void {
    if (this.macAddress != '') {
      this.getMeterCommunicationHistory();

    }

  }

  getAllCommnicationHistory() {
    this.store.dispatch(
      getAllCommnicationHistory({
        macAddress: this.macAddress ?? '',
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  getMeterCommunicationHistory() {
    this.store.dispatch(
      getCommnicationHistory({
        macAddress: this.macAddress ?? '',
        pageIndex: this.readingcurrentPage,
        pageSize: this.reading_pageSize,
        searchTerm: this.readingsSearchValue,
        filter: this.readingQueryString,
        orderBy: this.readingsorderByQuery,
      })
    );
  }
  public clearSearchValueChangeDevices(ev: string): void {

    if (ev.length == 0) {
      this.devicePageChange({ skip: 0, take: this.reading_pageSize })
    }
  }
  public onFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedPayloadConfigValue = value;
    this.devicePageChange({ skip: 0, take: this.reading_pageSize })
    // this.getDevices();
  }
  exportAll() {
    this.store.dispatch(reseExportCommnicationHistorytState());
    console.log("initaila")
    this.getAllCommnicationHistory();
    this.exportSubscription = this.store.pipe(select(getAllCommnicationHistoryResponse)).subscribe(({
      next: (response) => {
        console.log("initaila--3")

        this.exportdata = [];

        console.log(response)
        if (response.items?.length > 0) {
          this.exportdata = [...response.items];
          const transformedData = this.exportdata.map(
            (item: any) => ({
              'Mac Address': item.devicesAddress,
              'Communication Date': this.sharedService.dateFormateChnage(item.communicationDate),
              'Communication Status': item.communicationsStatus
            })
          );
          console.log("initaila--4")

          console.log(transformedData)
          this.PdfexportService.downloadExcel(transformedData, 'Communication History');
          this.ngOnDestroy()
        }
        else {
          this.sharedService.showMessageDialog(
            'No data to display',
            '',
            SweetAlertIcon.ERROR
          );
        }

      }, error: (err) => {
        console.log(err)
      },

    }))
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }



  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.readingsstate.filter = filter;
    console.log(this.readingsstate);
    this.readingQueryString = this.sharedService.createQuery(
      this.readingsstate
    );
    this.getMeterCommunicationHistory();
    console.log(this.readingQueryString);
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.readingsstate.sort = sort;
    console.log(this.readingsstate.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.readingsstate.sort
    );

    this.readingsorderByQuery = orderByQuery;
    this.getMeterCommunicationHistory();
  }


  protected devicePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.reading_pageSize = take;
    this.readingcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getMeterCommunicationHistory();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionReadings.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionReadings.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isdeviceRowSelected(row.dataItem)) {
        this.mySelectionReadings.push(row.dataItem);
      }
    });

    console.log(this.mySelectionReadings);
  }


  public isdeviceRowSelected = (rowArg: any) => {
    return this.mySelectionReadings.some((row: any) => row.id === rowArg.id);
  };


  protected dataStateChange(state: DataStateChangeEvent): void {
    console.log(state);

    this.sort = state.sort || [];
    this.reading_pageSize = state.take;
    // this.readings_filter = state.filter || { logic: 'and', filters: [] };
    // this.getreadings();
  }

  onReadingsExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }


}
