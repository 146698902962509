import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReadingsState } from './iot-readings.reducer';

export const getdeviceState = createFeatureSelector<ReadingsState>('readings');

export const getreadingsResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getreadingsResponse
);

export const getrawDataSuccessResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getrawDataSuccessResponse
);

export const getArchivedResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getArchivedResponse
);

export const getElectricStatusResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getElectricStatusResponse
);

export const getEb_Dg_StatusResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getEb_Dg_StatusResponse
);

export const getAllElectricStatusResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getAllElectricStatusResponse
);

export const getAllEb_Dg_StatusResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getAllEb_Dg_StatusResponse
);

export const getAllreadingsResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getAllreadingsResponse
);

export const getAllArchivedResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getAllArchivedResponse
);

export const GetAllrawDataSuccessResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.GetAllrawDataSuccessResponse
);

export const CommnicationHistoryResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.CommnicationHistoryResponse
);

export const getAllCommnicationHistoryResponse = createSelector(
  getdeviceState,
  (state: ReadingsState) => state.getAllCommnicationHistoryResponse
);

//