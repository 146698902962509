import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpDashboardComponent } from './rp-dashboard/rp-dashboard.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { IotMastersComponent } from './masters/iot-masters/iot-masters.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'dashboard' },
  /* {
    path: '',
    component: RpDashboardComponent,
  }, */

  /* {
    path: '',
    component: IotMastersComponent,
  }, */
  //{ path: 'dashboard', component: IotMastersComponent },

  { path: '', component: RpDashboardComponent },
  { path: 'dashboard', component: RpDashboardComponent },

  /*   {
      path: 'Master',
      component: IotMastersComponent,
    }, */

  {
    path: 'masters',
    loadChildren: () =>
      import('../features/masters/masters.module').then(
        (masters) => masters.MastersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('../features/configuration/configuration.module').then(
        (m) => m.ConfigurationModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'reports',
  //   loadChildren: () =>
  //     import('../features/rp-reports/rp-reports.module').then(
  //       (reports) => reports.ReportsModule
  //     ),
  //   canActivate: [AuthGuard],
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
